.resetPassword__success {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
}

.resetPassword__message {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
}

.resetPassword_form {
    margin-top: 16px;
}

.resetPassword__buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}