.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  right: 0;
  position: relative;
  transition: 0.5s;
  background-color: #2d2d2d;
  color: #fff;
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.app--gold {
  background-color: #151515;
}

.app.no-transition {
  transition: none !important;
}

.app.hideMenu {
  right: 0;
}

.app.openMenu {
  right: 350px;
}

a {
  color: #03D5E4;
  text-decoration: none;
}

h1 {
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: bolder;
}

h2 {
  font-size: 1rem;
}

.content {
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  flex-grow: 1;
}

.page {
  background-color: #2d2d2d;
  box-sizing: border-box;
  flex-grow: 1;
  margin-top: 87px;
  width: 100%;
}

.page-content {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.25rem 1rem;
  padding-bottom: 7rem;
  overflow-y: auto;
}

.home {
  padding: 0 0.375rem;
}

.page--bgRed {
  background-color: #CC2020;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--md {
  font-size: 1.125rem;
}

.text--lg {
  font-size: 1.25rem;
}

.text--xl {
  font-size: 1.5rem;
}

.text--2xl {
  font-size: 1.75rem;
}

.text--thin {
  font-weight: 400;
}

.text--gold {
  color: #FF1190;
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.home__title {
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0.5rem;
}

.leading-none {
  line-height: 1;
}

.font-normal	{
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.block {
  display: block;
}

form {
  margin-top: 1rem;
}

.form__instructions {
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
}

.form__title {
  background-color: #1D1D1D;
  color: #fff;
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-top: 1rem;
}

.form-group--checkbox {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  margin-top: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form__label {
  display: block;
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.form__label--inline {
  display: inline-block;
  margin-right: 0.5rem;
  padding-left: 1rem;
}

.form__input, .form__textarea {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  font-size: 1.125rem;
  padding: 0.5rem;
  margin-top: 0.25rem;
  height: 39px;
}

.form__labelinfo {
  font-size: 12px;
  font-weight: 500;
}

.form__checkbox {
  position: absolute;
  left: 0;
  top: 0;
}

.form__selectRow {
  display: flex;
  justify-content: space-between;
}

.form__selectRow > *:last-child {
  margin-right: 0;
}

.form__select {
  border-radius: 5px;
  border: none;
  padding: 0.5rem 0.75rem;
  flex: 1;
  margin-right: 1rem;
  margin-top: 0.5rem;
  height: 39px;
}

.form__buttons {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
}

.form__button {
  background-color: #03D5E4;
  border: none;
  color: #fff;
  -webkit-appearance: none;
  font-size: 1rem;
  display: inline-flex;
  padding: 0.625rem 2rem;
  cursor: pointer;
  font-weight: bold;
}

.form__button--inline {
  display: flex;
  justify-content: center;
}

.form__button:hover {
  outline: none;
}

.error {
  color: #ff8811;
  margin-left: 1rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.not-found {
  text-align: center;
  margin-top: 1.5rem;
}

.link-button {
  display: block;
  background-color: #03D5E4;
  color: #fff;
  padding: 1rem;
  width: 100%;
  border: none;
  appearance: none;
  font-size: 1rem;
  cursor: pointer;
}

.pill {
  font-size: 0.75rem;
  background-color: #03D5E4;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
  border-radius: 8px;
  color: #fff;
}

::-webkit-scrollbar {
  width: 0px;
}

.society {
  margin-top: 0.5em;
  padding: 0.375em 0.5em;
  background-color: #E0E0E0;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  display: flex;
  color: #333;
  align-items: center;
  justify-content: space-between;
}

.society span.close {
  cursor: pointer;
  height: 24px;
  width: 24px;
}

@media only screen and (max-width: 600px) {
  .app.openMenu {
    right: 60%;
  }
}